<template>
  <div>
    <div class="tabs">
      <div class="container">
        <p @click="tabActive = 1" :class="{active: tabActive === 1}">安全防护用品</p>
        <p @click="tabActive = 2" :class="{active: tabActive === 2}">安全技能提升培训</p>
      </div>
    </div>
    <div class="container" v-if="tabActive === 1">
      <p style="margin-top: 60px;margin-bottom: 30px;">安胜达有着强大的供应商链，为我们的客户提供劳保用品以及各种安全防护用品，产品品类/资质齐全，质量可靠，价格优惠，公司本着“诚信为本”为经营宗旨，建立了完善的销售体系，并以专业的业务素养和职业的服务技巧取得了众多企业的信赖。我们将一如既往的关注你的需求，提供最安全性，舒适性的产品，带给你最具品质的服务。</p>
      <div class="content">
        <ul>
          <li @click="liActive = 1" :class="{active: liActive === 1}"><Icon type="md-arrow-dropright" />头部防护</li>
          <li @click="liActive = 2" :class="{active: liActive === 2}"><Icon type="md-arrow-dropright" />面部防护</li>
          <li @click="liActive = 3" :class="{active: liActive === 3}"><Icon type="md-arrow-dropright" />听力防护</li>
          <li @click="liActive = 4" :class="{active: liActive === 4}"><Icon type="md-arrow-dropright" />呼吸防护</li>
          <li @click="liActive = 5" :class="{active: liActive === 5}"><Icon type="md-arrow-dropright" />身体防护</li>
          <li @click="liActive = 6" :class="{active: liActive === 6}"><Icon type="md-arrow-dropright" />四肢防护</li>
          <li @click="liActive = 7" :class="{active: liActive === 7}"><Icon type="md-arrow-dropright" />坠落防护</li>
          <li @click="liActive = 8" :class="{active: liActive === 8}"><Icon type="md-arrow-dropright" />密闭空间救助设备</li>
        </ul>
        <div class="right">
          <template v-if="liActive === 1">
            <div class="box" style="width: 49%;">
              <p>安全帽</p>
              <div>
                <img src="@/assets/img/aqfh-img-11.png" alt="">
                <img src="@/assets/img/aqfh-img-12.png" alt="">
                <img src="@/assets/img/aqfh-img-13.png" alt="">
                <img src="@/assets/img/aqfh-img-14.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 49%;">
              <p>阻燃焊工帽/披肩</p>
              <div>
                <img src="@/assets/img/aqfh-img-15.png" alt="">
                <img src="@/assets/img/aqfh-img-16.png" alt="">
                <img src="@/assets/img/aqfh-img-17.png" alt="">
              </div>
            </div>
          </template>
          <template v-if="liActive === 2">
            <div class="box" style="width: 32.2%;">
              <p>防护眼镜</p>
              <div>
                <img src="@/assets/img/aqfh-img-21.png" alt="">
                <img src="@/assets/img/aqfh-img-22.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;">
              <p>防护面具</p>
              <div>
                <img src="@/assets/img/aqfh-img-23.png" alt="">
                <img src="@/assets/img/aqfh-img-24.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;">
              <p>焊工面罩</p>
              <div>
                <img src="@/assets/img/aqfh-img-25.png" alt="">
                <img src="@/assets/img/aqfh-img-26.png" alt="">
              </div>
            </div>
          </template>
          <template v-if="liActive === 3">
            <div class="box" style="width: 32.2%;">
              <p>防噪音耳罩</p>
              <div>
                <img src="@/assets/img/aqfh-img-31.png" alt="">
                <img src="@/assets/img/aqfh-img-32.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;">
              <p>防护面耳塞</p>
              <div>
                <img src="@/assets/img/aqfh-img-33.png" alt="">
              </div>
            </div>
          </template>
          <template v-if="liActive === 4">
            <div class="box" style="width: 32.2%;">
              <p>防护口罩</p>
              <div>
                <img src="@/assets/img/aqfh-img-41.png" alt="">
                <img src="@/assets/img/aqfh-img-42.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;">
              <p>防毒面具</p>
              <div>
                <img src="@/assets/img/aqfh-img-43.png" alt="">
                <img src="@/assets/img/aqfh-img-44.png" alt="">
              </div>
            </div>
          </template>
          <template v-if="liActive === 5">
            <div class="box" style="width: 32.2%;">
              <p>反光背心</p>
              <div>
                <img src="@/assets/img/aqfh-img-51.png" alt="">
                <img src="@/assets/img/aqfh-img-52.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;">
              <p>防护服</p>
              <div>
                <img src="@/assets/img/aqfh-img-53.png" alt="">
                <img src="@/assets/img/aqfh-img-54.png" alt="">
              </div>
            </div>
          </template>
          <template v-if="liActive === 6">
            <div class="box" style="width: 32.2%;">
              <p>手部防护</p>
              <div>
                <img src="@/assets/img/aqfh-img-61.png" alt="">
                <img src="@/assets/img/aqfh-img-62.png" alt="">
                <img src="@/assets/img/aqfh-img-63.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;">
              <p>足部防护</p>
              <div>
                <img src="@/assets/img/aqfh-img-64.png" alt="">
                <img src="@/assets/img/aqfh-img-65.png" alt="">
              </div>
            </div>
          </template>
          <template v-if="liActive === 7">
            <div class="box" style="width: 32.2%;">
              <p>安全带</p>
              <div>
                <img src="@/assets/img/aqfh-img-71.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;">
              <p>安全绳</p>
              <div>
                <img src="@/assets/img/aqfh-img-72.png" alt="">
                <img src="@/assets/img/aqfh-img-73.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;">
              <p>缓降器/自锁速差器</p>
              <div>
                <img src="@/assets/img/aqfh-img-74.png" alt="">
                <img src="@/assets/img/aqfh-img-75.png" alt="">
              </div>
            </div>
          </template>
          <template v-if="liActive === 8">
            <div class="box" style="width: 32.2%;">
              <p>气体检测仪</p>
              <div>
                <img src="@/assets/img/aqfh-img-81.png" alt="">
                <img src="@/assets/img/aqfh-img-82.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;">
              <p>通风设备</p>
              <div>
                <img src="@/assets/img/aqfh-img-83.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;">
              <p>呼吸设备</p>
              <div>
                <img src="@/assets/img/aqfh-img-84.png" alt="">
                <img src="@/assets/img/aqfh-img-85.png" alt="">
              </div>
            </div>
            <div class="box" style="width: 32.2%;margin-left: 0;">
              <p>吊升装备</p>
              <div>
                <img src="@/assets/img/aqfh-img-86.png" alt="">
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="container" v-if="tabActive === 2">
      <p style="margin-top: 60px;margin-bottom: 30px;">安胜达依托自身专业化专家团队、知名院校、国家安全生产协会的技术支持，针对机械、工贸、建筑、化工等不同行业的具体需求，为客户量身打造最优质、最专业的安全技能提升培训服务。采取公开课、定制化培训、线上直播课等多种培训形式，帮助客户提高安全专业水平，提升企业安全管理水平，铸造安全文化。</p>
      <div class="content">
        <ul>
          <li @click="liActive = 1" :class="{active: liActive === 1}"><Icon type="md-arrow-dropright" />注册安全工程师培训</li>
          <li @click="liActive = 2" :class="{active: liActive === 2}"><Icon type="md-arrow-dropright" />定制化安全专业培训</li>
        </ul>
        <div class="right" v-if="liActive === 1">
          <div class="display-flex" style="border: 1px solid #D9D9D9;">
            <img height="260px" src="@/assets/img/aqjnpx-img01.png" alt="">
            <div style="padding: 30px;">
              <h5 class="fs-18 mb-20">注册安全工程师培训</h5>
              <p class="fs-14">安胜达注安培训课程，以名师精讲，紧扣教材内容，详解考试重难点，浓缩80%教材内容，紧扣考试大纲，覆盖当年必考点，配有历年真题、章节练习题，让您学练合一，提高做题能力，得分能力，串讲点题卷，查缺补漏，一 一攻克必考考点。</p>
            </div>
          </div>
          <p style="margin-top: 60px;margin-bottom: 30px;" class="fs-24">报考条件 </p>
          <div class="condition">
            <div>
              <p>安全工程及相关专业大专学历，工作满5年；或取得其他专业大专，工作满7年。</p>
              <p>专安全工程及相关专业本科学历，工作满3年；或取得其他专业本科，工作满5年。</p>
            </div>
            <div>
              <p>安全工程及相关专业第二学士学位或研究生班毕业，工作满2年；或取得其他专业第二学士学位或研究生班毕业，工作满3年。</p>
              <p style="padding-bottom: 41px;">安全工程及相关专业硕士学位，工作满1年；其他专业硕士学位，工作满2年。</p>
            </div>
            <div>
              <p>博士学位；或取得其他专业博士学位，工作满1年。</p>
              <p>取得初级注册安全工程师职业资格后，从事安全生产业务满3年。</p>
            </div>
          </div>
          <p style="margin-top: 60px;margin-bottom: 30px;" class="fs-24">培训优势 </p>
          <span class="color-info fs-14">中级安全工程师考试包含4个科目，各科目所涉及的知识点以及对知识点的考察力度也不一样。加入我们，帮助你杜绝眼高手低，提高解答题目的效率，传授做题的方法和技巧，以及最为重要的提高解题的准确性和效率性。</span>
          <div class="display-flex jc-space-between mt-20">
            <div style="width: 24%;border: 1px solid #CCCCCC;">
              <img width="100%" src="@/assets/img/aqjnpx-img02.png" alt="">
              <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">专业教授、小班辅导</p>
            </div>
            <div style="width: 24%;border: 1px solid #CCCCCC;">
              <img width="100%" src="@/assets/img/aqjnpx-img03.png" alt="">
              <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">专业教授、小班辅导</p>
            </div>
            <div style="width: 24%;border: 1px solid #CCCCCC;">
              <img width="100%" src="@/assets/img/aqjnpx-img04.png" alt="">
              <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">培训内容与考题紧密契合</p>
            </div>
            <div style="width: 24%;border: 1px solid #CCCCCC;">
              <img width="100%" src="@/assets/img/aqjnpx-img05.png" alt="">
              <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">亲自参与安全事故调查</p>
            </div>
          </div>
          <div>
            <p style="margin-top: 60px;margin-bottom: 30px;" class="fs-24">服务质量 </p>
            <p class="color-info fs-14">我们始终秉持“焦距客户的需求，提供专业、增值、便捷的管理提升方案和服务，持续为客户创造最大价值。”的企业文化精神，为每一个选择、信任我们的客户负责。</p>
            <p class="color-info fs-14">我们在传递信任，传递知识、也在传递“专业”和“真诚”。</p>
            <p class="color-info fs-14 mb-20">第一年未通过考试，第二年可免费再次培训，只要你来，通往中级注册安全工程师的路上，我们始终做你的后盾，做你的加持，也做你的引路人。</p>
          </div>
        </div>
        <div class="right" v-if="liActive === 2">
          <div class="display-flex jc-space-between">
            <img src="@/assets/img/aqjnpx-img06.png" height="260px" width="49%"/>
            <img src="@/assets/img/aqjnpx-img07.png" height="260px" width="49%"/>
          </div>
          <span class="fs-14 color-info mt-20">安胜达针对政府安全监管、企业安全生产运营从咨询服务入手，发现客户切实存在的问题并编制高度定制化、针对性的安全培训课程，为客户提供最切实的解决方案，避免培训走过场及流于形式，从根源上解决客户症结所在，授之以鱼更授之以渔。</span><br>
          <span class="fs-14 color-info mt-20">公司通过多年的HSE体系建设、现场管理和咨询培训经历的总结归纳，逐步形成一套独具特色的HSE培训方法，多种教学培训方式结合，有效提高培训效果。</span>
          <p style="color:#0077C8;padding: 20px 0;background-color:#D0E6F5;width: 100%;text-align: center;margin-top: 20px;">现场体验教学+实战教练指导+经典案例分析+视频教学引导+现场模拟+教学互动升华</p>
          <p class="fs-14 color-info mt-20 mb-20" style="text-align: center;width: 100%;">我们提供不限于以下内容的培训课程，如果您有特别要求，我们也可根据您的需求由资深安全专家定制开发相关安全专业课程。</p>
          <div style="width: 100%;display: flex;align-items: center;justify-content: center;">
            <Table border :columns="columns" :data="data"></Table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Other',
  components: {},
  props: {},
  data () {
    return {
      tabActive: 1,
      liActive: 1,
      columns: [
        {
          title: '安全管理类课程',
          key: 'col1',
          width: '400px',
          align: 'center'
        },
        {
          title: '安全技能/工具类课程',
          key: 'col2',
          width: '400px',
          align: 'center'
        }
      ],
      data: [
        {
          col1: '安全领导力、执行力、安全文化（2天）',
          col2: '消防安全培训与演练（1天）'
        },
        {
          col1: '安全管理体系（2天）',
          col2: '风险分析（1天）'
        },
        {
          col1: '现代HSE管理（3天）',
          col2: '受限空间（1天）'
        },
        {
          col1: '事故调查与根源分析（2天）',
          col2: '高处作业（1天）'
        },
        {
          col1: '工艺安全管理PSM（2天）',
          col2: '工作许可PTW（2天）'
        },
        {
          col1: '安全审计员培训（2天）',
          col2: '上锁挂牌（1天）'
        },
        {
          col1: '安全履职能力建设（2天）',
          col2: '呼吸防护（1天）'
        },
        {
          col1: '承包商管理（2天）',
          col2: '硫化氢防护（1天）'
        },
        {
          col1: 'HSE绩效管理（1天）',
          col2: '公共安全培训（2天）'
        },
        {
          col1: 'HSE审核（2天）',
          col2: '急救培训（1天）'
        },
        {
          col1: '应急管理（1天）',
          col2: '防御性驾驶（2天）'
        },
        {
          col1: '风险管控（1天）',
          col2: '员工心理健康管理（1天）'
        },
        {
          col1: '员工心理健康管理（1天）',
          col2: '职业健康管理（2天）'
        }
      ]
    }
  },
  filter: {},
  computed: {},
  watch: {
    tabActive () {
      this.liActive = 1
    }
  },
  methods: {},
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {

  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  width: 100%;
  height: 70px;
  background-color: #F2F2F2;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      cursor: pointer;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .active {
      background-color: #42C348;
      color: white;
    }
  }
}
.content {
  width: 100%;
  border: 1px solid #D9D9D9;
  display: flex;
  margin-bottom: 40px;
  ul {
    padding: 20px 0;
    list-style: none;
    background: #F8F8F8;
    width: 240px;
    li {
      cursor: pointer;
      padding: 15px;
      color: #666666;
      i {
        margin-right: 6px;
      }
    }
    .active {
      background-color: #42C348;
      color: white;
    }
  }
  .right {
    width: 100%;
    padding: 20px;
    border-left: 1px solid #D9D9D9;
    display: flex;
    flex-wrap: wrap;
    .box + .box {
      margin-left: 20px;
    }
    .box {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #DADADA;
      height: 48%;
      p {
        font-weight: 500;
      }
      &>div {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}
.condition {
  width: 100%;
  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      width: calc(50% - 10px);
      padding: 20px 45px;
      font-size: 14px;
      position: relative;
      border: 1px solid #D9D9D9;
      margin-bottom: 20px;
      &:before {
        content: ' ';
        position: absolute;
        left: 22px;
        top: 25px;
        width: 10px;
        height: 10px;
        background-color: #3CB133;
      }
    }
  }
}
</style>
